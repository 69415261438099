import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';

import * as UserPreferencesActions from '../actions/user-preferences.actions';
export const userPreferencesOptionsFeatureKey = 'userPreferencesOptions';

export type UserPreferencesOptionsState = Record<string, { loading: boolean; loaded: boolean; options: any[] }>;

const initialState: UserPreferencesOptionsState = {};

export const userPreferencesOptionsStoreReducer = createReducer(
	initialState,
	on(UserPreferencesActions.getUserPreferenceOptionsByKeySuccess, (state, { key, options }): UserPreferencesOptionsState => {
		let optionKey = state[key];
		if (!optionKey) {
			optionKey = { loading: false, loaded: true, options };
		} else {
			optionKey = { ...optionKey, loading: false, loaded: true, options };
		}
		return { ...state, [key]: optionKey };
	}),
	on(UserPreferencesActions.clearUserPreferencesOptionsByKeySuccess, (state, { key }): UserPreferencesOptionsState => {
		const newState = cloneDeep(state);
		delete newState[key];
		return newState;
	}),
	on(UserPreferencesActions.setUserPreferencesOptionsByKeyLoading, (state, { key }): UserPreferencesOptionsState => {
		let optionKey = state[key];
		if (!optionKey) {
			optionKey = { loading: true, loaded: false, options: [] };
		} else {
			optionKey = { ...optionKey, loading: true, loaded: false };
		}
		return { ...state, [key]: optionKey };
	}),
	on(UserPreferencesActions.clearUserPreferencesOptions, (): UserPreferencesOptionsState => initialState),
);
