import { createAction, props } from '@ngrx/store';

export const getUserPreferenceByKey = createAction(
	'[User Preferences] Get User Preference Based On Key',
	props<{ key: string }>()
);

export const getUserPreference = createAction(
	'[User Preferences] Get User Preference'
);

export const removeUserPreferenceByKey = createAction(
	'[User Preferences] Remove User Preference Based On Key',
	props<{ key: string }>()
);

export const getUserPreferenceOptionsByKey = createAction(
	'[User Preferences] Get User Preference Options Based On Key',
	props<{ key: string }>()
);

export const saveUserPreferenceByKey = createAction(
	'[User Preferences] Set User Preference Options Based On Key',
	props<{ key: string; value: string }>()
);

export const saveDefaultCustomerUserPreferenceByKey = createAction(
	'[User Preferences] Save Customers Default Preference Based On Key',
	props<{ key: string; value: string }>()
);

export const saveUserPreferenceByKeyAndUserId = createAction(
	'[User Preferences] Save Customers Default Preference Based On Key And UserID',
	props<{ key: string; value: string; userId: string }>()
);

export const getUserPreferenceByKeySuccess = createAction(
	'[User Preferences] Get User Preference Based On Key Success',
	props<{ key: string; value: string }>()
);

export const getUserPreferenceSuccess = createAction(
	'[User Preferences] Get User Preference Success',
	props<{ userPreferences: Record<string, string> }>()
);

export const removeUserPreferenceByKeySuccess = createAction(
	'[User Preferences] Remove User Preference Based On Key Success',
	props<{ key: string }>()
);

export const getUserPreferenceOptionsByKeySuccess = createAction(
	'[User Preferences] Get User Preference Options Based On Key Success',
	props<{ key: string; options: string[] }>()
);

export const saveUserPreferenceByKeySuccess = createAction(
	'[User Preferences] Set User Preference Options Based On Key Success',
	props<{ key: string; value: string }>()
);

export const saveUserPreferenceByKeyFailure = createAction(
	'[User Preferences] Set User Preference Options Based On Key Failure',
	props<{ error: string; key: string }>()
);

export const clearUserPreferences = createAction(
	'[User Preferences] Clear User Preferences'
);

export const clearUserPreferencesOptionsByKeySuccess = createAction(
	'[User Preferences] Clear User Preferences Options By Key Success',
	props<{ key: string }>()
);

export const setUserPreferencesOptionsByKeyLoading = createAction(
	'[User Preferences] Set User Preferences Options By Key Loading',
	props<{ key: string }>()
);

export const clearUserPreferencesOptions = createAction(
	'[User Preferences] Clear User Preferences Options'
);

export const setUserPreferencesLoading = createAction(
	'[User Preferences] Set User Preferences Loading'
);
