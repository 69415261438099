import { createSelector } from '@ngrx/store';

import { UserPreferenceState } from '../reducers/user-preferences.reducers';

export const selectUserPreferencesStoreFeature = (state: any) => state.userPreferencesStore as UserPreferenceState;

export const selectUserPreferences = (key: string) => createSelector(
	selectUserPreferencesStoreFeature,
	(state: UserPreferenceState) => state.values[key]
);

export const selectUserPreferencesState = createSelector(
	selectUserPreferencesStoreFeature,
	(state: UserPreferenceState) => state
);
