import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, tap } from 'rxjs';

import { SnackbarService } from '@@shared/snackbar';

import * as UserPreferencesActions from '../actions/user-preferences.actions';
import { UserPreferencesService } from '../services/user-preferences.service';

@Injectable({
	providedIn: null
})
export class UserPreferencesEffects {

	readonly actions$ = inject(Actions);
	readonly #userPreferencesService = inject(UserPreferencesService);
	readonly #snackbarService = inject(SnackbarService);

	readonly removeUserPreferenceByKey$ = createEffect(() => this.actions$.pipe(
		ofType(UserPreferencesActions.removeUserPreferenceByKey),
		tap(({ key }) => this.#snackbarService.openLoadBar(`Removing User Preference ${key}`)),
		mergeMap(({ key }) => this.#userPreferencesService.deletePreference(key)
			.pipe(
				tap(() => this.#snackbarService.openSnackBar(`User Preference ${key} Removed`)),
				map(() => UserPreferencesActions.removeUserPreferenceByKeySuccess({ key }))
			)
		)
	));

	readonly getUserPreference$ = createEffect(() => this.actions$.pipe(
		ofType(UserPreferencesActions.getUserPreference),

		mergeMap(() => this.#userPreferencesService.getAllPreferences()
			.pipe(
				map((userPreferences) => UserPreferencesActions.getUserPreferenceSuccess({ userPreferences }))
			)
		)
	));

	readonly saveUserPreferenceByKeySuccess$ = createEffect(() => this.actions$.pipe(
		ofType(UserPreferencesActions.saveUserPreferenceByKey),
		tap(() => this.#snackbarService.openLoadBar('Saving User Preference')),
		mergeMap(({ key, value }) => this.#userPreferencesService.savePreference(key, value)
			.pipe(
				tap(() => this.#snackbarService.openSnackBar('User Preference Saved')),
				map(() => UserPreferencesActions.saveUserPreferenceByKeySuccess({ key, value })),
				catchError((error) => {
					this.#snackbarService.openSnackBar('User Preference Save Failed');
					return of(UserPreferencesActions.saveUserPreferenceByKeyFailure({ key, error: error as string }));
				})
			)
		)
	));

	readonly getUserPreferenceByKeySuccess$ = createEffect(() => this.actions$.pipe(
		ofType(UserPreferencesActions.getUserPreferenceByKey),
		mergeMap(({ key }) => this.#userPreferencesService.getPreference(key)
			.pipe(
				map((value) => UserPreferencesActions.getUserPreferenceByKeySuccess({ key, value }))
			)
		)
	));

	readonly getUserPreferenceOptionsByKey$ = createEffect(() => this.actions$.pipe(
		ofType(UserPreferencesActions.getUserPreferenceOptionsByKey),
		mergeMap(({ key }) => this.#userPreferencesService.getPreferenceOptions(key)
			.pipe(
				map((options) => UserPreferencesActions.getUserPreferenceOptionsByKeySuccess({ key, options: options.options }))
			)
		)
	));
}
