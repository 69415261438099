import { createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';

import * as UserPreferencesActions from '../actions/user-preferences.actions';
export const userPreferencesFeatureKey = 'userPreferences';

export interface UserPreferenceState {
	loading: boolean;
	loaded: boolean;
	values: Record<string, string>;
}

const initialState: UserPreferenceState = {
	loading: false,
	loaded: false,
	values: {}
};

export const userPreferencesStoreReducer = createReducer(
	initialState,
	on(UserPreferencesActions.setUserPreferencesLoading, (state): UserPreferenceState => ({
		...state,
		loading: true,
	})),
	on(UserPreferencesActions.removeUserPreferenceByKeySuccess, (state, { key }): UserPreferenceState => {
		const newState = cloneDeep(state);
		delete newState.values[key];
		return newState;
	}),
	on(UserPreferencesActions.getUserPreferenceSuccess, (state, { userPreferences }): UserPreferenceState => ({ loading: false, loaded: true, values: { ...userPreferences } })),
	on(UserPreferencesActions.saveUserPreferenceByKeySuccess, (state, { key, value }): UserPreferenceState => {
		const newState = cloneDeep(state);
		newState.values[key] = value;
		return newState;
	})
);
